"use client";

import {
  Options,
  documentToReactComponents,
} from "@contentful/rich-text-react-renderer";
import { BLOCKS, Document, INLINES, Text } from "@contentful/rich-text-types";
import { Box, ListItemIcon, ListItemText } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

import { useContext } from "react";

import theme from "../../../styles/globalTheme";
import { ContentSectionThemeContext } from "../../../utils/contexts";
import { getIdFromString } from "../../../utils/helperFunctions";
import { IProductFieldsModified } from "../../../utils/shopify/createCart";
import Badge from "../../badge";
import CoachAvatar from "../../coach-avatar";
import CTAButton from "../../cta-button";
import ListIcon from "../../icons/list-icon.svg";
import IdAnchor from "../../id-anchor";
import TeaserProduct from "../../teaser-product";
import ButtonList from "../button-list-section";

interface RichTextRendererProps {
  richTextDocument: Document;
  options?: Options;
}

// options to define how to render content elements in the DOM
export const defaultOptions: Options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <Typography
        variant="body1"
        component="span"
        marginBottom={useTheme().spacing(2)}
        whiteSpace="pre-wrap"
        color={useContext(ContentSectionThemeContext).textColor}
        sx={{
          width: "100%",
          display: "block",
          wordBreak: "break-word",
          a: {
            color:
              useContext(ContentSectionThemeContext).textColor ??
              theme.palette.green.dark,
          },
        }}
      >
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <Typography
        variant="h2"
        component="h2"
        sx={{
          wordBreak: "break-word",
        }}
        color={useContext(ContentSectionThemeContext).textColor}
        marginBottom={useTheme().spacing(2)}
        paddingTop={useTheme().spacing(2)}
      >
        <IdAnchor
          id={getIdFromString(
            (node.content as Text[]).map((txtNode) => txtNode.value).join(" ")
          )}
        />
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <Typography
        variant="h3"
        component="h3"
        sx={{
          wordBreak: "break-word",
        }}
        color={useContext(ContentSectionThemeContext).textColor}
        marginBottom={useTheme().spacing(2)}
        paddingTop={useTheme().spacing(2)}
      >
        <IdAnchor
          id={getIdFromString(
            (node.content as Text[]).map((txtNode) => txtNode.value).join(" ")
          )}
        />
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <Typography
        variant="h4"
        component="h4"
        sx={{
          wordBreak: "break-word",
        }}
        color={useContext(ContentSectionThemeContext).textColor}
        marginBottom={useTheme().spacing(2)}
        paddingTop={useTheme().spacing(2)}
      >
        <IdAnchor
          id={getIdFromString(
            (node.content as Text[]).map((txtNode) => txtNode.value).join(" ")
          )}
        />
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_6]: (node, children) => (
      <Typography
        variant="caption"
        color={useContext(ContentSectionThemeContext).textColor}
      >
        <IdAnchor
          id={getIdFromString(
            (node.content as Text[]).map((txtNode) => txtNode.value).join(" ")
          )}
        />
        {children}
      </Typography>
    ),
    [BLOCKS.UL_LIST]: (node, children) => (
      <List color={useContext(ContentSectionThemeContext).textColor}>
        {children}
      </List>
    ),
    [BLOCKS.OL_LIST]: (node, children) => (
      <List
        sx={{
          color: useContext(ContentSectionThemeContext).textColor,
        }}
      >
        {children}
      </List>
    ),
    [BLOCKS.LIST_ITEM]: (node, children) => (
      <ListItem
        sx={{
          alignItems: "flex-start",
          color: useContext(ContentSectionThemeContext).textColor,
        }}
      >
        <ListItemIcon
          sx={{
            mt: theme.spacing(-0.25),
            color:
              useTheme().components?.ListItemIcon.regular[
                useContext(ContentSectionThemeContext).theme
              ].backgroundColor,
            minWidth: "auto",
            marginRight: theme.spacing(1),
          }}
        >
          <ListIcon width="32" height="32" />
        </ListItemIcon>
        <ListItemText>{children}</ListItemText>
      </ListItem>
    ),
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      const { data } = node;
      if (data.target.sys.contentType.sys.id === "ctaButton") {
        return (
          <Box
            sx={{
              marginBottom: useTheme().spacing(2),
              marginTop: useTheme().spacing(3),
            }}
          >
            <CTAButton {...data.target.fields} />
          </Box>
        );
      } else if (data.target.sys.contentType.sys.id === "teaserProduct") {
        return (
          <TeaserProduct
            textColor={undefined}
            product={
              data.target.fields.product.fields as IProductFieldsModified
            }
            {...data.target.fields}
          />
        );
      } else if (data.target.sys.contentType.sys.id === "buttonList") {
        return <ButtonList {...data.target.fields} />;
      } else if (data.target.sys.contentType.sys.id === "badge") {
        return <Badge {...data.target.fields} />;
      } else if (data.target.sys.contentType.sys.id === "coachAvatar") {
        return <CoachAvatar coachAvatar={data.target} />;
      }
    },
    [INLINES.EMBEDDED_ENTRY]: (node) => {
      const { data } = node;
      if (data.target.sys.contentType.sys.id === "ctaButton") {
        return (
          <Box
            sx={{
              display: "inline",
              mx: theme.spacing(1),
            }}
          >
            <CTAButton {...data.target.fields} />
          </Box>
        );
      } else if (data.target.sys.contentType.sys.id === "badge") {
        return (
          <Box
            sx={{
              display: "inline",
              position: "relative",
              bottom: useTheme().spacing(0.25),
            }}
          >
            <Badge {...data.target.fields} />
          </Box>
        );
      } else if (data.target.sys.contentType.sys.id === "coachAvatar") {
        return (
          <Box
            sx={{
              display: "inline",
              marginBottom: useTheme().spacing(3),
              marginTop: useTheme().spacing(3),
            }}
          >
            <CoachAvatar coachAvatar={data.target} />
          </Box>
        );
      }
    },
  },
};

/**
 * Helper component to render rich text field content as React components
 * with a set of default options.
 *
 * @param {RichTextRendererProps} props
 * @returns JSX.Element
 */
const RichTextRenderer = ({
  richTextDocument,
  options,
}: RichTextRendererProps) => {
  return (
    <>
      {documentToReactComponents(richTextDocument, options || defaultOptions)}
    </>
  );
};
export default RichTextRenderer;
