"use client";

import { ArrowDropDown } from "@mui/icons-material";
import { Button, IconButton, Menu, MenuItem } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box } from "@mui/system";

import React, { FC, useContext, useState } from "react";

import {
  IButtonListFields,
  ICtaButton,
} from "../../../@types/generated/contentful";
import { ContentSectionThemeContext } from "../../../utils/contexts";
import CTAButton from "../../cta-button";

const ButtonList = (props: IButtonListFields) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    buttons,
    alignment,
    maxColumns = Infinity,
    iconsOnlyOnMobile,
  } = props;
  const displayedButtons = buttons.filter(
    (_, i) => i < maxColumns - 1 + (iconsOnlyOnMobile && isSmallScreen ? 5 : 0)
  );
  const collapsedButtons = buttons.filter(
    (button) => displayedButtons.indexOf(button) == -1
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: alignment == "zentriert" ? "center" : "flex-start",
        columnGap: iconsOnlyOnMobile && isSmallScreen ? theme.spacing(2) : 0,
        rowGap: iconsOnlyOnMobile && isSmallScreen ? theme.spacing(2) : 0,
      }}
    >
      {displayedButtons.map((button, i) => (
        <Box
          key={`listButton${i}`}
          sx={{
            ...(alignment === "zentriert"
              ? { mx: theme.spacing(1), my: theme.spacing(1) }
              : { mr: theme.spacing(1), mb: theme.spacing(1) }),
          }}
        >
          <CTAButton
            {...button.fields}
            noText={iconsOnlyOnMobile && isSmallScreen}
          />
        </Box>
      ))}
      {collapsedButtons.length > 0 && (
        <ButtonDropdownList
          buttons={collapsedButtons}
          onlyIcons={iconsOnlyOnMobile && isSmallScreen}
        ></ButtonDropdownList>
      )}
    </Box>
  );
};

export default ButtonList;

interface ButtonDropDownProps {
  buttons: ICtaButton[];
  onlyIcons: boolean;
}

const ButtonDropdownList: FC<ButtonDropDownProps> = ({
  buttons,
  onlyIcons,
}) => {
  const theme = useTheme();

  const themeContext = useContext(ContentSectionThemeContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: any) => {
    event.currentTarget && setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {onlyIcons ? (
        <IconButton
          sx={{
            py: theme.spacing(1.5),
            px: 0,
            mb: "auto",
            width: "44px",
            height: "44px",
            minWidth: "44px",
          }}
          onClick={handleClick}
        >
          <ArrowDropDown
            style={{
              color:
                theme.components?.ButtonBase[buttons[0].fields.type][
                  themeContext.theme
                ].color,
            }}
          />
        </IconButton>
      ) : (
        <Button
          startIcon={<ArrowDropDown />}
          variant="contained"
          color={buttons[0].fields.type}
          href={""}
          onClick={handleClick}
          sx={{
            py: theme.spacing(1.5),
            px: 0,
            mb: "auto",
            width: "44px",
            height: "44px",
            minWidth: "44px",
            " span": {
              marginRight: 0,
              marginLeft: 0,
            },
          }}
        />
      )}

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "rgba(255,255,255,0)",
            boxShadow: "none",
          },
        }}
      >
        {buttons.map((button) => (
          <MenuItem
            key={button.sys.id}
            onClick={() => {
              handleClose();
            }}
            sx={{
              ml: theme.spacing(-2),
            }}
          >
            <CTAButton {...button.fields}></CTAButton>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
