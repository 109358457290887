import { useTheme } from "@mui/material/styles";

import { useMemo } from "react";

function useContentSectionThemeContext(backgroundColor: string | undefined) {
  const { palette: colors } = useTheme();

  return useMemo(() => {
    let textColor;
    let theme: "light" | "dark" = "light";
    let isBackgroundSet =
      backgroundColor && backgroundColor != "none" ? true : false;
    if (backgroundColor === colors.darkGreen.dark) {
      textColor = colors.cream.dark;
      theme = "dark";
    } else if (backgroundColor === colors.darkGreen.main) {
      textColor = colors.pureWhite.main;
      theme = "dark";
    } else if (backgroundColor === colors.cream.dark) {
      textColor = colors.darkGreen.dark;
    } else if (backgroundColor === colors.blue.main) {
      textColor = colors.pureWhite.main;
      theme = "dark";
    } else if (backgroundColor === colors.softGreen.main) {
      textColor = colors.pureWhite.main;
      theme = "dark";
    } else if (backgroundColor === colors.softGreen.lightest) {
      textColor = colors.softBlack.dark;
      theme = "light";
    } else if (backgroundColor === colors.sand.lightest) {
      textColor = colors.softBlack.dark;
      theme = "light";
    } else if (backgroundColor === colors.corall.light) {
      textColor = colors.pureWhite.main;
      theme = "light";
    } else if (backgroundColor === colors.orange.dark) {
      textColor = colors.pureWhite.main;
      theme = "dark";
    } else if (backgroundColor === colors.limeGreen.main) {
      textColor = colors.darkRed.main;
      theme = "dark";
    } else if (backgroundColor === colors.darkRed.main) {
      textColor = colors.pureWhite.main;
      theme = "dark";
    } else if (backgroundColor === colors.darkGreige.dark) {
      textColor = colors.pureWhite.main;
      theme = "light";
    } else if (backgroundColor === colors.darkGreige.main) {
      textColor = colors.darkRed.main;
      theme = "light";
    } else if (backgroundColor === colors.darkGreige.light) {
      textColor = colors.darkRed.main;
      theme = "light";
    } else if (backgroundColor === colors.softBlack.light) {
      textColor = colors.darkGreen.dark;
      theme = "dark";
    } else if (backgroundColor === colors.pureWhite.main) {
      textColor = colors.softBlack.dark;
      theme = "light";
    } else if (backgroundColor === colors.softBlack.dark) {
      textColor = colors.pureWhite.main;
      theme = "light";
    } else if (backgroundColor === colors.limeGreen.light) {
      textColor = colors.darkRed.main;
      theme = "dark";
    } else {
      textColor = colors.softBlack.dark;
    }
    return { textColor, theme, isBackgroundSet };
  }, [
    backgroundColor,
    colors.cream.dark,
    colors.darkGreen.dark,
    colors.softBlack.dark,
    colors.darkGreen.main,
    colors.pureWhite.main,
    colors.blue.main,
    colors.softGreen.main,
    colors.softGreen.lightest,
    colors.sand.lightest,
  ]);
}

export default useContentSectionThemeContext;
