import { Chip } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { IBadgeFields } from "../../@types/generated/contentful";
import useContentSectionThemeContext from "../../hooks/useContentSectionThemeContext";

const Badge = ({ label, backgroundColor, size }: IBadgeFields) => {
  const theme = useTheme();
  const textColor = useContentSectionThemeContext(backgroundColor).textColor;
  const isSmall = size === "Klein";
  return (
    <Chip
      label={label}
      sx={{
        backgroundColor,
        color: textColor,
        position: "relative",
        textTransform: "uppercase",
        fontSize: isSmall
          ? theme.typography.caption.fontSize
          : theme.typography.body2.fontSize,
        height: "unset",
        py: isSmall ? 0.5 : 1,
        px: isSmall ? 1.5 : 2,
        span: {
          padding: 0,
        },
      }}
    />
  );
};

export default Badge;
