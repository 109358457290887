const IdAnchor = (props: { id: string }) => {
  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          opacity: 0,
          position: "absolute",
          top: "-189px",
        }}
        id={props.id}
      ></div>
    </div>
  );
};

export default IdAnchor;
