"use client";

import { Box, Button, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import React, { useState } from "react";

import {
  ICourseUpsellProduct,
  IProduct,
  IProductVariant,
} from "../../@types/generated/contentful";
import {
  formatPrice,
  getShopifyProductsWithExtras,
} from "../../utils/helperFunctions";
import {
  IProductFieldsModified,
  createCheckoutApiURL,
  getVariantIdsToCheckout,
} from "../../utils/shopify/createCart";
import {
  pushDataLayerBeginCheckout,
  pushDataLayerBeginCheckoutFromString,
} from "../../utils/trackingEvents/begin-checkout";
import {
  getTotalPriceWithUpsells,
  getTotalStrikethroughWithUpsells,
} from "../../utils/upsells";
import UpsellProduct from "../teaser-product-upsell-element";

interface ITeaserProductProps {
  buttonLabel: string;
  internal: string;
  badgeText?: string;
  strikeThroughPrice?: string;
  subtext?: string;
  textColor?: string;
  product: IProduct | IProductVariant;
  price?: string;
  upsellProducts?: ICourseUpsellProduct[];
  discount?: string;
  linkUrl?: string;
}

/**
 * component renders a product for a hero teaser. Some data comes from contentful, some from shopify.
 * @param {ITeaserProductProps} props
 * @returns JSX.Element
 */
const TeaserProduct: React.FC<ITeaserProductProps> = ({
  buttonLabel,
  internal,
  badgeText,
  strikeThroughPrice,
  subtext,
  price,
  textColor,
  product,
  upsellProducts,
  discount,
  linkUrl,
}) => {
  const theme = useTheme();
  const colors = theme.palette;

  // const { setCartItemAmount, cart } = useContext(CartContext);

  const [upsellSelected, setUpsellSelected] = useState(
    Array(upsellProducts ? upsellProducts.length : 0).fill(false)
  );

  const productModified = product.fields as IProductFieldsModified;
  const { shopifyProduct } = productModified;
  const shopifyPrice = shopifyProduct.price.amount;
  const currencyCode = shopifyProduct.minPrice.currencyCode;
  const displayPrice = price !== undefined ? price : shopifyPrice;
  const getCheckoutIds = () => {
    let productIds = getVariantIdsToCheckout(productModified);
    getSelectedUpsells().forEach((upsell) =>
      productIds.push(upsell.fields.shopifyVariantGID)
    );
    return productIds;
  };

  const getSelectedUpsells = () =>
    upsellProducts
      ? upsellProducts?.filter((upsell, i) => upsellSelected[i])
      : [];

  const handleUpsellSelect = (isSelected: boolean, index: number) => {
    let arr = upsellSelected.slice(0);
    arr[index] = isSelected;
    setUpsellSelected(arr);
  };

  return (
    <Box sx={{ marginTop: theme.spacing(3) }}>
      {upsellProducts && upsellProducts.length && (
        <Box
          sx={{
            mb: theme.spacing(5),
          }}
        >
          {upsellProducts.map((product, i) => (
            <UpsellProduct
              key={`heroUpsellProduct${i}`}
              product={product}
              isChecked={upsellSelected[i]}
              handleSelect={() => handleUpsellSelect(!upsellSelected[i], i)}
            />
          ))}
        </Box>
      )}

      {badgeText && (
        <Box
          sx={{
            borderRadius: theme.spacing(2),
            paddingX: theme.spacing(2),
            paddingY: theme.spacing(0.5),
            backgroundColor: colors.softBlack.light,
            width: "fit-content",
          }}
        >
          <Typography
            variant="caption"
            component="p"
            sx={{ color: colors.darkGreen.dark }}
          >
            {badgeText}
          </Typography>
        </Box>
      )}
      <Box sx={{ display: "flex", marginTop: theme.spacing(2) }}>
        {((strikeThroughPrice && !isNaN(parseFloat(strikeThroughPrice))) ||
          getTotalStrikethroughWithUpsells(
            displayPrice,
            strikeThroughPrice,
            getSelectedUpsells()
          ) > 0) && (
          <Typography
            variant="h3"
            component="h3"
            sx={{
              fontWeight: "normal",
              color: colors.softBlack.main,
              marginRight: theme.spacing(2),
            }}
          >
            <s>
              {formatPrice(
                getTotalStrikethroughWithUpsells(
                  displayPrice,
                  strikeThroughPrice,
                  getSelectedUpsells()
                ),
                "EUR"
              )}
            </s>
          </Typography>
        )}
        <Typography
          variant="h3"
          component="h3"
          sx={{ color: textColor || "unset" }}
        >
          {formatPrice(
            getTotalPriceWithUpsells(
              parseFloat(displayPrice),
              getSelectedUpsells()
            ),
            currencyCode
          )}
        </Typography>
      </Box>
      <Typography
        variant="caption"
        component="p"
        sx={{ color: textColor || "unset" }}
      >
        {subtext}
      </Typography>

      <Button
        className="teaser-buy-button"
        onClick={() => {
          linkUrl
            ? pushDataLayerBeginCheckoutFromString(linkUrl)
            : pushDataLayerBeginCheckout(
                getShopifyProductsWithExtras(productModified)
              );
        }}
        sx={{
          marginTop: theme.spacing(3),
          paddingX: theme.spacing(7),
          paddingY: theme.spacing(1.75),
        }}
        id={internal}
        color="primary"
        variant="contained"
        href={
          linkUrl ? linkUrl : createCheckoutApiURL(getCheckoutIds(), discount)
        }
      >
        {buttonLabel}
      </Button>
    </Box>
  );
};

export default TeaserProduct;
