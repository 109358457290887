import { ICourseUpsellProduct } from "../../@types/generated/contentful";
import { ICourseUpsellProductFieldsModified } from "../shopify/createCart";

export const getProductUpsellPrice = (product: ICourseUpsellProduct) => {
  const modifiedFields = product.fields as ICourseUpsellProductFieldsModified;
  if (modifiedFields.price) {
    return parseFloat(modifiedFields.price);
  }
  return parseFloat(modifiedFields.shopifyProduct.minPrice.amount);
};

export const getStrikethroughUpsellPrice = (product: ICourseUpsellProduct) => {
  const modifiedFields = product.fields as ICourseUpsellProductFieldsModified;
  if (modifiedFields.strikeThroughPrice) {
    return parseFloat(modifiedFields.strikeThroughPrice);
  }
  return getProductUpsellPrice(product);
};

export const getTotalPriceWithUpsells = (
  price: number,
  upsellProducts: ICourseUpsellProduct[]
) =>
  (upsellProducts
    ? upsellProducts.reduce(
        (prev, curr, i) => prev + getProductUpsellPrice(curr),
        0
      )
    : 0) + price;

export const getTotalStrikethroughWithUpsells = (
  basePrice: string,
  baseStrikethroughPrice: string | undefined,
  upsellProducts: ICourseUpsellProduct[]
) => {
  const upsellStrikethrough = upsellProducts
    ? upsellProducts.reduce(
        (prev, curr, i) => prev + getStrikethroughUpsellPrice(curr),
        0
      )
    : 0;

  //if no strikethrough present on any upsells or the product itself
  if (!baseStrikethroughPrice && upsellStrikethrough <= 0) {
    return 0;
  }

  const baseStrikethrough = baseStrikethroughPrice
    ? parseFloat(baseStrikethroughPrice.replace(",", "."))
    : upsellStrikethrough > 0
    ? parseFloat(basePrice.replace(",", "."))
    : 0;

  const totalPrice = getTotalPriceWithUpsells(
    parseFloat(basePrice.replace(",", ".")),
    upsellProducts
  );
  const totalStrikethrough = baseStrikethrough + upsellStrikethrough;
  return totalPrice !== totalStrikethrough ? totalStrikethrough : 0;
};
