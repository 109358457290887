import { Avatar, Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import Image from "next/image";

import React from "react";

import { ICoachAvatar } from "../../@types/generated/contentful";
import { withHttps } from "../../utils/helperFunctions";

interface CoachAvatarProps {
  coachAvatar: ICoachAvatar;
  textColor?: string;
}

/**
 * component renders avatar, name and role of coach
 * @param {CoachAvatarProps} props
 * @returns JSX.Element
 */
const CoachAvatar: React.FC<CoachAvatarProps> = ({
  coachAvatar,
  textColor,
}) => {
  const { spacing } = useTheme();
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Avatar
        sx={{
          width: "48px",
          height: "48px",
        }}
      >
        <Image
          src={withHttps(coachAvatar.fields.image.fields.image.fields.file.url)}
          alt={coachAvatar.fields.image.fields.altText}
          width={48}
          height={48}
        />
      </Avatar>
      <Box sx={{ ml: spacing(1) }}>
        <Typography
          variant="h5"
          component="h4"
          sx={{ color: textColor || "unset" }}
        >
          {coachAvatar.fields.name}
        </Typography>
        <Typography
          variant="body2"
          component="p"
          sx={{ color: textColor || "unset" }}
        >
          {coachAvatar.fields.role}
        </Typography>
      </Box>
    </Box>
  );
};

export default CoachAvatar;
