import { Box, Checkbox, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import Image from "next/image";

import { ICourseUpsellProduct } from "../../@types/generated/contentful";
import useContentSectionThemeContext from "../../hooks/useContentSectionThemeContext";
import { formatPrice, withHttps } from "../../utils/helperFunctions";

interface IUpsellProductProps {
  product: ICourseUpsellProduct;
  isChecked: boolean;
  handleSelect: () => void;
}
const UpsellProduct: React.FC<IUpsellProductProps> = ({
  product,
  isChecked,
  handleSelect,
}: IUpsellProductProps) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const shopifyProduct = (product.fields as any).shopifyProduct;
  const { price, strikeThroughPrice, title, subtitle, image, backgroundColor } =
    product.fields;
  const textColor = useContentSectionThemeContext(backgroundColor).textColor;
  return (
    <Box
      className="upsellProductBox"
      sx={{
        position: "relative",
        display: "inline-flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        mt: theme.spacing(2),
        // mb: theme.spacing(9),
        mr: "75px",
        p: theme.spacing(2),
        pr: 0,
        backgroundColor: backgroundColor,
        color: textColor,
        borderRadius: theme.spacing(1),
      }}
    >
      <Checkbox
        sx={{
          color: textColor,
          mr: theme.spacing(1),
          "&.Mui-checked": {
            //checked state always has primary color. Only adjust when the background color is the primary color
            color:
              backgroundColor === theme.palette.primary.main
                ? textColor
                : theme.palette.primary.main,
          },
          "& .MuiSvgIcon-root": { fontSize: { md: 24, lg: 32 } },
        }}
        checked={isChecked}
        onClick={() => handleSelect()}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          ml: theme.spacing(0),
          mr: theme.spacing(10),
        }}
      >
        <Typography
          sx={{ color: textColor }}
          variant={isSmallScreen ? "h5" : "h4"}
        >
          {title}
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          {strikeThroughPrice && (
            <Typography
              sx={{
                color: textColor,
                fontWeight: "normal",
                mr: theme.spacing(1),
              }}
              variant={isSmallScreen ? "h5" : "h4"}
            >
              <s>{strikeThroughPrice}€</s>
            </Typography>
          )}
          <Typography
            sx={{ color: textColor }}
            variant={isSmallScreen ? "h5" : "h4"}
          >
            {formatPrice(
              price ? price : shopifyProduct.minPrice.amount,
              shopifyProduct.minPrice.currencyCode
            )}
          </Typography>
        </Box>
        {subtitle && (
          <Typography sx={{ color: textColor }} variant="caption">
            {subtitle}
          </Typography>
        )}
      </Box>

      <Box
        sx={{
          position: "relative",
          flex: 1,
        }}
      >
        {image && (
          <Box
            sx={{
              position: "absolute",
              width: "150px",
              height: "150px",
              transform: "translate(50%,-50%)",
              top: "50%",
              right: 0,
              zIndex: 9,
            }}
          >
            <Image
              sizes={"150px"}
              style={{ objectFit: "contain" }}
              src={withHttps(image.fields.file.url)}
              alt={image.fields.description}
              fill
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default UpsellProduct;
