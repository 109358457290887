"use client";

import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

import { useContext } from "react";

import { IContentSectionFields } from "../../@types/generated/contentful";
import useContentSectionThemeContext from "../../hooks/useContentSectionThemeContext";
import { ContentSectionThemeContext } from "../../utils/contexts";

/** @typedef ContainerProps */
interface ContainerProps {
  children: React.ReactNode | React.ReactNode[];
  backgroundColor?: IContentSectionFields["backgroundColor"];
  isStroke?: boolean;
  fullWidth?: IContentSectionFields["fullWidth"];
  lowTopPadding?: Boolean;
  lowBottomPadding?: Boolean;
}

const StyledContainer = styled("section")<{
  backgroundColor: ContainerProps["backgroundColor"];
  isStroke?: boolean;
  fullWidth: boolean;
  lowTopPadding: Boolean | undefined;
  lowBottomPadding: Boolean | undefined;
}>(
  ({
    theme,
    backgroundColor,
    isStroke,
    fullWidth = true,
    lowTopPadding = false,
    lowBottomPadding = false,
  }) => {
    const { textColor } = useContext(ContentSectionThemeContext);

    const dynamicStyles = {
      padding: theme.spacing(
        lowTopPadding ? 3 : 5,
        2,
        lowBottomPadding ? 3 : 5,
        2
      ),
      marginTop: 0,
      marginBottom: 0,
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(
          lowTopPadding ? 3 : 10,
          5,
          lowBottomPadding ? 3 : 10,
          5
        ),
      },
      "> .hero-teaser, > .hero-teaser-home, > .hero-teaser-article": {
        marginTop: {
          xs: `-${theme.spacing(4)}`,
          sm: `-${theme.spacing(5)}`,
          md: `-${theme.spacing(4)}`,
        },
      },
    };

    const reducedWithStyles = {
      maxWidth: 1200,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      marginLeft: "auto",
      marginRight: "auto",
      boxSizing: "border-box" as const,
      borderRadius: theme.components?.ContentSection.borderRadius,
    };
    const strokeStyles = {
      borderTop: "2px solid " + theme.palette.softBlack.light,
      borderBottom: "2px solid " + theme.palette.softBlack.light,
      borderRadius: 0,
    };
    return {
      color: textColor,
      backgroundColor,
      ...dynamicStyles,
      ...(!fullWidth && reducedWithStyles),
      ...(isStroke && strokeStyles),
    };
  }
);

/**
 * Main stucturing layout component
 * @typedef ContainerProps
 * @param   {string} backgroundColor the color of the container background
 * @param {boolean} extendedWidth - if true, no horizontal padding gets applied
 * @returns a `<section>` tag containing the given children
 */
const CustomContainer = ({
  backgroundColor = "none",
  isStroke = false,
  fullWidth = true,
  children,
  lowTopPadding,
  lowBottomPadding,
}: ContainerProps) => {
  const provider = useContentSectionThemeContext(backgroundColor);

  return (
    <ContentSectionThemeContext.Provider value={provider}>
      <StyledContainer
        className="container"
        isStroke={isStroke}
        backgroundColor={backgroundColor}
        fullWidth={fullWidth}
        lowTopPadding={lowTopPadding}
        lowBottomPadding={lowBottomPadding}
      >
        <Box
          sx={{
            width: "100%",
            boxSizing: "border-box",
            maxWidth: 1200,
            marginX: "auto",
          }}
        >
          {children}
        </Box>
      </StyledContainer>
    </ContentSectionThemeContext.Provider>
  );
};
export default CustomContainer;
