/**
 * Generate the complete title by the current page title
 * and the title suffix.
 *
 * @param {string} pageTitle
 * @param {string} suffix
 * @returns {string} generated title
 */
export default function generateTitle(
  pageTitle: string,
  suffix?: string
): string {
  return suffix ? `${pageTitle} | ${suffix}` : pageTitle;
}
