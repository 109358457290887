import { Avatar, Box, IconButton } from "@mui/material";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import { Asset } from "contentful";

import Image from "next/image";

import { useContext } from "react";

import { ILink } from "../../@types/generated/contentful";
import useContentSectionThemeContext from "../../hooks/useContentSectionThemeContext";
import useLink from "../../hooks/useLink";
import { ContentSectionThemeContext } from "../../utils/contexts";
import {
  getShopifyProductsWithExtras,
  withHttps,
} from "../../utils/helperFunctions";
import { saveCoursepageSource } from "../../utils/localstorage/navigationTracking";
import { IProductFieldsModified } from "../../utils/shopify/createCart";
import {
  pushDataLayerBeginCheckout,
  pushDataLayerBeginCheckoutFromString,
} from "../../utils/trackingEvents/begin-checkout";
import { COURSE_LISTS } from "../../utils/trackingEvents/select-item";

interface Props {
  internal: string;
  /** Label */
  label: string;

  /** Link Referenz */
  link: ILink;

  icon?: Asset;

  onlyIcon?: boolean;

  type: "primary" | "secondary" | "big";
  className?: string;

  noText?: boolean;

  backgroundColor?: string;
}

/**
 * Component renders ant button of type primary or secondary. Button links to an internal page or external url.
 *
 * @param {ICtaButtonFields} props
 * @returns JSX.Element
 */
const CTAButton = (props: Props) => {
  const { getLinkProps } = useLink();
  const theme = useTheme();
  const sectionContext = useContext(ContentSectionThemeContext);
  const {
    backgroundColor,
    link,
    internal,
    label,
    type,
    icon,
    onlyIcon,
    noText,
    ...rest
  } = props;
  const itemContext = useContentSectionThemeContext(backgroundColor);
  const significantThemeContext = backgroundColor
    ? itemContext
    : sectionContext;
  const { href, linkTarget, shopifyProductFields } = getLinkProps(link.fields);
  const buttonBaseType =
    type === "primary" || type === "big"
      ? theme.components?.ButtonBase.primary
      : theme.components?.ButtonBase.secondary;

  //Rtl logo is so wide it needs special treatment
  const isRTLButton = icon?.fields.title == "RTL Plus Logo";

  return !icon || !(onlyIcon || noText) ? (
    <Button
      id={internal}
      variant="contained"
      color={type === "big" ? "primary" : type}
      href={href}
      onClick={() => writeTrackingEvents(shopifyProductFields, link)}
      target={linkTarget}
      className={
        shopifyProductFields?.shopifyGID ||
        href.startsWith("https://shop.hellohealth.de/cart/")
          ? "teaser-buy-button"
          : ""
      }
      {...rest}
      sx={{
        backgroundColor,
        borderColor:
          buttonBaseType?.[significantThemeContext.theme]?.borderColor,
        color: backgroundColor
          ? significantThemeContext.textColor
          : buttonBaseType?.[significantThemeContext.theme]?.color,
        ...(type === "big" && {
          paddingX: theme.spacing(7),
          paddingY: theme.spacing(1.75),
        }),
        " img": {
          objectFit: "contain",
          marginRight: theme.spacing(2),
        },
        ...(backgroundColor && {
          "&:hover": {
            backgroundColor: theme.components?.HeroTeaserHomeButton.hoverColor,
          },
        }),
      }}
      startIcon={
        icon ? (
          <Box
            sx={{
              maxHeight: "28px",
              minHeight: "28px",
              minWidth: "28px",
              height: "28px",
              position: "relative",
              " path": {
                fill: "black",
              },
            }}
          >
            <Image
              style={{
                maxWidth: isRTLButton ? "150px" : "60px",
                margin: 0,
                marginTop: isRTLButton ? "-11px" : "0px",

                marginRight: "-2px",
                marginLeft: "-4px",
                overflow: "visible",
                borderRadius: 0,
                objectFit: "contain",
                ...(isRTLButton
                  ? {
                      width: "50px",
                      height: "50px",
                    }
                  : {}),
              }}
              width={isRTLButton ? 60 : undefined}
              height={isRTLButton ? 60 : undefined}
              fill={!isRTLButton}
              src={withHttps(icon.fields.file.url.substring(1))}
              alt={internal.replace(/\s/g, "") + "buttonIcon"}
            />
          </Box>
        ) : null
      }
    >
      {label}
    </Button>
  ) : (
    <IconButton
      id={internal}
      onClick={() => writeTrackingEvents(shopifyProductFields, link)}
      target={linkTarget}
      className={shopifyProductFields?.shopifyGID ? "teaser-buy-button" : ""}
      {...rest}
      href={href}
      color={type === "big" ? "primary" : type}
      sx={{
        objectFit: "contain",
        overflow: "visible",
        mx: isRTLButton ? theme.spacing(3.5) : 0,
        width: "36px",
        height: "36px",
      }}
    >
      <Avatar
        alt={internal.replace(/\s/g, "") + "buttonIcon"}
        sx={{
          objectFit: "contain",
          overflow: "visible",
          height: isRTLButton ? "unset" : "36px",
          width: isRTLButton ? "90px" : "36px",
          marginRight: 0,
          " img": {
            objectFit: "contain",
          },
        }}
        src={withHttps(icon.fields.file.url.substring(1))}
      />
    </IconButton>
  );
};

function writeTrackingEvents(
  product: IProductFieldsModified | null,
  link: ILink
) {
  const pageType = link.fields.internalLink?.sys.contentType.sys.id;
  const externalLink = link.fields.externalLink;
  if (pageType === "coursePage" || pageType == "productDetailPage") {
    if (window.location.pathname.includes("programme")) {
      saveCoursepageSource(COURSE_LISTS.COURSE_OVERVIEW);
    } else if (window.location.pathname == "/") {
      saveCoursepageSource(COURSE_LISTS.HOMEPAGE);
    } else {
      saveCoursepageSource(COURSE_LISTS.OTHER);
    }
  }

  if (
    externalLink &&
    externalLink.startsWith("https://shop.hellohealth.de/cart/")
  ) {
    pushDataLayerBeginCheckoutFromString(externalLink);
  } else if (product) {
    pushDataLayerBeginCheckout(getShopifyProductsWithExtras(product));
  }
}

export default CTAButton;
